import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import clsx from "clsx";
import { ClassNameMap } from "../types/StandardProps";
import { InputBaseClassKey, InputBaseProps } from "./types";
import useForkRef from "../utils/useForkRef";
import { useFormControl } from "@mui/material";
import formControlState from "@mui/material/FormControl/formControlState";
import { isFilled } from "./utils";
import { TextareaAutosize } from "../TextareaAutosize";
import FormControlContext from "@mui/material/FormControl/FormControlContext";
import { twMerge } from "tailwind-merge";
import overrideStyles, { addDebugInfo } from "../utils/overrideStyles";

/*
 ! Refer to the README.md in the TailwindTextField component for guidelines on styling, adding styles, and debugging.
  ! Avoid using "!important" in className props, passed classes, or componentStyles within the component.

 To override `InputBase` componentStyles with Tailwind CSS, use the classes prop and specify the keys for styles you want to override
  
 Ex: To overwrite styles corresponding to `root` and `input:disabled` key, we can do as follow
 <InputBase
  classes={{
    root: "....",
    "input:disabled": "....",
  }}
  placeholder="Search"
/>

*/
const componentStyles: ClassNameMap<InputBaseClassKey> = {
  /* Base styles applied to `div` wrapping input*/
  root: " text-[rgba(0,0,0,1)] cursor-text inline-flex relative text-[1.33rem] box-border items-center font-primary-rhsans font-[400] bg-[#ffffff] leading-[1.1876em]",

  /* Styles applied to `div` when color is secondary*/
  colorSecondary: "",

  /* Styles applied to `div` if the component is a descendant of `FormControl`*/
  formControl: "",

  /* Styles applied to the `div` element if `margin="dense"`. */
  marginDense: "",

  /* Styles applied to the `div` element if `multiline={true}`. */
  multiline: "pt-[6px] pb-[7px]",

  /* Styles applied to the `div` element if `multiline={true}` and `margin="dense". */
  "multiline:marginDense": "pt-[3px]",

  /* Styles applied to the `div` element if `startAdornment` is provided. */
  adornedStart: "",

  /* Styles applied to the `div` element if `endAdornment` is provided. */
  adornedEnd: "",

  /* Styles applied to the `div` element if `fullWidth={true}`. */
  fullWidth: "w-full",

  /* Styles applied to the `div` element if the component is focused. */
  focused: "",

  /* Styles applied to the `div` element if `error={true}`. */
  error: "",

  /* Styles applied to the `div` element if `disabled={true}`. */
  disabled: "text-[rgba(0,0,0,0.38)] cursor-default",

  /* Base Styles applied to the `input` element. */
  input:
    "text-[1.167rem] p-[14px] tracking-inherit text-inherit border-0 box-content bg-transparent h-[1.1876em] m-0 block min-w-0 w-full  invalid:shadow-none placeholder:text-current placeholder:opacity-[0.42]",

  /* Styles applied to the `input` element if `margin="dense"`. */
  "input:marginDense": "pt-[3px]",

  /* Styles applied to the `input` element if `multiline={true}`. */
  "input:multiline": "h-auto resize-none p-0",

  /* Styles applied to the `input` element if `startAdornment` is provided. */
  "input:adornedStart": "",

  /* Styles applied to the `input` element if `endAdornment` is provided. */
  "input:adornedEnd": "",

  /* Styles applied to the `input` element if `hiddenLabel={true}`. */
  "input:hiddenLabel": "",

  /* Styles applied to the `input` element if `hiddenLabel={true}` and `margin="dense"`. */
  "input:hiddenLabel:marginDense": "",

  /* Styles applied to the `input` element if the component is focused. */
  "input:focus": "outline-none",

  /* Styles applied to the `input` element if `error={true}`. */
  "input:error": "",

  /* Styles applied to the `input` element if `disabled={true}`. */
  "input:disabled": "opacity-[1]"
};

const useEnhancedEffect =
  typeof window === "undefined" ? React.useEffect : React.useLayoutEffect;

const InputBaseRenderFunction: ForwardRefRenderFunction<any, InputBaseProps> = (
  props,
  ref
) => {
  const {
    "aria-describedby": ariaDescribedby,
    autoComplete,
    autoFocus,
    classes = {},
    className: classNameProp,
    color,
    defaultValue,
    disabled = false,
    endAdornment,
    error = false,
    fullWidth = false,
    id,
    inputComponent = "input",
    inputProps: inputPropsProp = {},
    inputRef: inputRefProp,
    margin,
    multiline = false,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    readOnly = false,
    renderSuffix,
    rows,
    rowsMax,
    rowsMin,
    startAdornment,
    type = "text",
    value: valueProp,
    ...other
  } = props;

  /* overrideStyles iterates through each key in componentStyles and overrides its styles with the corresponding styles from classes for matching keys. */
  const overriddenStyles: ClassNameMap<InputBaseClassKey> = overrideStyles(
    "InputBase",
    componentStyles,
    classes
  );

  //value can either be directly pass as props ore it can be in inputProps
  const value = inputPropsProp.value != null ? inputPropsProp.value : valueProp;

  //When Component first renders, useRef(valueProp!=null ) creates a ref with .current set to true or false depend on valueProp.
  // isControlled remains constant during re-renders as useRef creates a persistent object that survives re-renders
  const isControlled = useRef(valueProp != null).current;

  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

  const handleInputRefWarning = useCallback(instance => {
    // if (process.env.NODE_ENV !== "production")
    if (true) {
      if (instance && instance.nodeName !== "INPUT" && !instance.focus) {
        console.error(
          [
            "Material-UI: You have provided a `inputComponent` to the input component",
            "that does not correctly handle the `inputRef` prop.",
            "Make sure the `inputRef` prop is called with a HTMLInputElement."
          ].join("\n")
        );
      }
    }
  }, []);

  const handleInputPropsRefProp = useForkRef(
    inputPropsProp.ref,
    handleInputRefWarning
  );
  const handleInputRefProp = useForkRef(inputRefProp, handleInputPropsRefProp);
  const handleInputRef = useForkRef(inputRef, handleInputRefProp);

  const [focused, setFocused] = useState(false);

  const muiFormControl = useFormControl();

  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(
      function () {
        if (muiFormControl) {
          //  return muiFormControl.registerEffect();
        }

        return undefined;
      },
      [muiFormControl]
    );
  }

  const fcs = formControlState({
    props: props,
    muiFormControl: muiFormControl,
    states: [
      "color",
      "disabled",
      "error",
      "hiddenLabel",
      "margin",
      "required",
      "filled"
    ]
  });
  fcs.focused = muiFormControl ? muiFormControl.focused : focused;

  useEffect(() => {
    if (!muiFormControl && disabled && focused) {
      setFocused(false);

      if (onBlur) {
        (onBlur as any)();
      }
    }
  }, [muiFormControl, disabled, focused, onBlur]);

  const onFilled = muiFormControl && muiFormControl.onFilled;
  const onEmpty = muiFormControl && muiFormControl.onEmpty;

  const checkDirty = useCallback(
    obj => {
      if (isFilled(obj)) {
        if (onFilled) {
          onFilled();
        }
      } else if (onEmpty) {
        onEmpty();
      }
    },
    [onFilled, onEmpty]
  );

  useEnhancedEffect(() => {
    if (isControlled) {
      checkDirty({
        value: value
      });
    }
  }, [value, checkDirty, isControlled]);

  var handleFocus = event => {
    if (fcs.disabled) {
      event.stopPropagation();
      return;
    }

    if (onFocus) onFocus(event);

    if (inputPropsProp.onFocus) inputPropsProp.onFocus(event);

    muiFormControl && muiFormControl.onFocus
      ? muiFormControl.onFocus(event) //event
      : setFocused(true);
  };

  var handleBlur = event => {
    if (onBlur) onBlur(event);

    if (inputPropsProp.onBlur) inputPropsProp.onBlur(event);

    muiFormControl && muiFormControl.onBlur
      ? muiFormControl.onBlur(event) //event
      : setFocused(false);
  };

  var handleChange = function handleChange(event) {
    if (!isControlled) {
      var element = event.target || inputRef.current;

      if (element == null) {
        throw new Error(
          "Tailwind InputBase : Expected valid input target. Did you use a custom `inputComponent` and forget to forward refs? "
        );
      }

      checkDirty({
        value: element.value
      });
    }

    for (
      var _len = arguments.length,
        args = new Array(_len > 1 ? _len - 1 : 0),
        _key = 1;
      _key < _len;
      _key++
    ) {
      args[_key - 1] = arguments[_key];
    }

    if (inputPropsProp.onChange) {
      inputPropsProp.onChange.call(inputPropsProp, event, ...args);
    } // Perform in the willUpdate

    if (onChange) {
      onChange.call(void 0, event, ...args);
    }
  }; // Check the input state on mount, in case it was filled by the user
  // or auto filled by the browser before the hydration (for SSR).

  useEffect(() => {
    checkDirty(inputRef.current);
  }, []);

  var handleClick = function handleClick(event) {
    if (inputRef.current && event.currentTarget === event.target) {
      inputRef.current.focus();
    }

    if (onClick) {
      onClick(event);
    }
  };

  var InputComponent = inputComponent;

  let inputProps: any = {
    ...inputPropsProp,
    ref: handleInputRef
  };

  if (typeof InputComponent !== "string") {
    inputProps = {
      inputRef: handleInputRef,
      type: type,
      ...inputProps,
      ref: null
    };
  } else if (multiline) {
    if (rows && !rowsMax && !rowsMin) InputComponent = "textarea";
    else {
      inputProps = {
        rows: rows,
        rowsMax: rowsMax,

        ...inputProps
      };
      InputComponent = TextareaAutosize;
    }
  } else {
    inputProps = {
      type: type,

      ...inputProps
    };
  }

  var handleAutoFill = function handleAutoFill(event) {
    // Provide a fake value as Chrome might not let you access it for security reasons.
    checkDirty(
      event.animationName === "mui-auto-fill-cancel"
        ? inputRef.current
        : {
            value: "x"
          }
    );
  };

  useEffect(
    function () {
      if (muiFormControl) muiFormControl.adornedStart = Boolean(startAdornment); //setAdornedStart
    },
    [muiFormControl, startAdornment]
  );

  /*
  ! ⚠️ DO NOT change the order of styles inside the `className` prop passed to `div and InputComponent` in the return statement.
 ! Changing the order will cause later styles to override earlier ones, leading to unexpected behavior.
 */

  return (
    <div
      className={twMerge(
        clsx(
          overriddenStyles.root,
          { [overriddenStyles.colorSecondary]: color === "secondary" },
          { [overriddenStyles.formControl]: muiFormControl },
          addDebugInfo("InputBase-root", "className", classNameProp ?? ""),
          { [overriddenStyles.marginDense]: fcs.margin === "dense" },
          { [overriddenStyles.multiline]: multiline },
          {
            [overriddenStyles["multiline:marginDense"]]:
              multiline && margin === "dense"
          },
          { [overriddenStyles.adornedStart]: startAdornment },
          { [overriddenStyles.adornedEnd]: endAdornment },
          { [overriddenStyles.fullWidth]: fullWidth },
          { [overriddenStyles.focused]: fcs.focused },
          { [overriddenStyles.error]: fcs.error },
          { [overriddenStyles.disabled]: fcs.disabled }
        )
      )}
      onClick={handleClick}
      ref={ref as any}
      {...other}
    >
      {startAdornment}
      <FormControlContext.Provider value={undefined}>
        <InputComponent
          aria-invalid={fcs.error}
          aria-describedby={ariaDescribedby}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={fcs.disabled}
          id={id}
          onAnimationStart={handleAutoFill}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          required={fcs.required}
          rows={rows}
          value={value}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          {...inputProps}
          className={twMerge(
            clsx(
              overriddenStyles.input,
              addDebugInfo(
                "InputBase-input",
                "className",
                inputPropsProp.className ?? ""
              ),
              {
                [overriddenStyles["input:marginDense"]]: fcs.margin === "dense"
              },
              { [overriddenStyles["input:multiline"]]: multiline },
              { [overriddenStyles["input:adornedStart"]]: startAdornment },
              { [overriddenStyles["input:adornedEnd"]]: endAdornment },
              { [overriddenStyles["input:hiddenLabel"]]: fcs.hiddenLabel },
              {
                [overriddenStyles["input:hiddenLabel:marginDense"]]:
                  fcs.hiddenLabel && fcs.margin === "dense"
              },
              { [overriddenStyles["input:focus"]]: fcs.focused },
              { [overriddenStyles["input:error"]]: fcs.error },
              { [overriddenStyles["input:disabled"]]: fcs.disabled }
            )
          )}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </FormControlContext.Provider>
      {endAdornment}
      {renderSuffix ? renderSuffix({ ...fcs, startAdornment }) : null}
    </div>
  );
};

export const InputBase = forwardRef(InputBaseRenderFunction);
