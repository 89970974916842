import React, { FC, useState, useCallback, useEffect, useContext } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import {
  Theme,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography
} from "@mui/material";
import he from "he";
import memoize from "utils/memoize";
import { LoadingContext } from "graphql-client/contexts/LoadingContext";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHCheckmarkIcon from "icon-checkmark";
import { processEnvServer } from "hooks/useSsrHooks";
import classNames from "classnames";
import { useEnv } from "hooks/useEnv";
import TailwindCheckbox from "@RHCommerceDev/component-tailwind-checkbox";
import analyticsLoader from "analytics/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unchecked: {
      border: "0.5px solid #000000"
    },
    checked: {
      backgroundColor: "#000000",
      border: "0.5px solid #000000",
      boxShadow: "inset 0 0 0px 2px #F9F7F4"
    },
    cbLabel: {
      "&&": {
        width: "100%",
        margin: 0,
        alignItems: "center",
        "&:last-child": {
          marginBottom: 0
        },
        marginBottom: "36px",
        [theme.breakpoints.up("md")]: {
          marginBottom: "29px"
        }
      },
      "&.Mui-disabled": {
        opacity: "0.5"
      }
    }
  })
);

export interface CheckboxTypeProps {
  id?: string;
  option: SearchRefinementLink;
  handleChangeNavState: (cleansedURL: string) => void;
  refinement: SearchRefinementMenu;
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
}
let domLoaded = false;

const CheckboxType: FC<CheckboxTypeProps> = memoize(
  ({
    id,
    option,
    handleChangeNavState,
    refinement,
    labelStyle,
    labelClassName
  }) => {
    const { isLoading: dataIsLoading } = useContext(LoadingContext);
    const classes = useStyles();
    const env = useEnv();
    const [checked, setChecked] = useState(false);
    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseBody2"]
    });

    const optionLabel = option?.label?.toLowerCase();
    const shouldHideFilterCount =
      optionLabel === "in-stock" ||
      optionLabel === "sale" ||
      optionLabel === "new arrivals";

    const onCheckboxChange = useCallback(
      e => {
        setChecked(!checked);
        let navigationState = option?.navigationState || "";
        try {
          //Adding sale param for the sale filter only
          const decodedPath = decodeURIComponent(navigationState);
          if (
            option?.label === "Sale" &&
            decodedPath?.includes('sku_showOnly:("Sale")') &&
            !checked
          ) {
            const saleString = "&sale=true";
            navigationState = `${navigationState}${saleString}`;
          }

          if (!processEnvServer) {
            analyticsLoader(a =>
              a.emitAnalyticsEvent(
                document.querySelector("#spa-root > *")! as HTMLElement,
                a.EVENTS.FACET_CLICK.INT_TYPE,
                {
                  facet_name: refinement?.displayName,
                  facet_value:
                    refinement?.displayName === option?.label || option?.label
                }
              )
            );
          }
          handleChangeNavState?.(navigationState);
        } catch (error) {
          console.error("Failed to decode URI:", error);
        }
      },
      [
        checked,
        option?.navigationState,
        option?.label,
        handleChangeNavState,
        refinement?.displayName
      ]
    );

    useEffect(() => {
      setChecked(option?.isSelected);
    }, [option?.isSelected]);

    useEffect(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const Ntt = urlParams.get("Ntt");
      if (
        option?.label?.toLowerCase() === "sale" &&
        Ntt?.toLowerCase().includes("sale") &&
        !option?.isSelected &&
        option?.count > 0 &&
        !checked &&
        !domLoaded
      ) {
        onCheckboxChange(option);
        domLoaded = true;
      }
    }, []);

    const handleSpecialCharacter = label => {
      return label.replace(/&#34;/g, '"');
    };

    return refinement.name === "Sort" ? (
      <div
        tabIndex={0}
        role="listItem"
        aria-label={`${
          checked ? "selected" : "unselected"
        } ${handleSpecialCharacter(option?.label)}`}
        className="flex items-center cursor-pointer mb-4 last:mb-0"
      >
        <Typography
          id={id}
          className={classNames(typographyStyles.rhBaseBody2, "mb:3")}
          onClick={onCheckboxChange}
        >
          {option?.label || ""}
        </Typography>
        <div>{checked && <RHCheckmarkIcon />}</div>
      </div>
    ) : (
      <FormControlLabel
        id={id}
        className={classes.cbLabel}
        disabled={processEnvServer ? false : dataIsLoading}
        control={
          env?.FEATURE_TAILWIND_COMPONENTS ? (
            <TailwindCheckbox
              data-testid={option?.label}
              id={option?.label}
              key={option?.label}
              checked={Boolean(checked)}
              onChange={onCheckboxChange}
              className="!h-3 !w-3 mt-[5px] mb-[5px] mr-[10px] border-[0.5px]"
              iconType="block"
            />
          ) : (
            <Checkbox
              className="h-auto w-auto border-none m-0 mr-2 bg-transparent hover:bg-transparent focus-within:outline focus-within:outline-3 focus-within:outline-black"
              icon={
                <div
                  className={classNames(
                    `h-3 w-3 ${checked ? classes.checked : classes.unchecked}`
                  )}
                />
              }
              checkedIcon={
                <div
                  className={classNames(
                    `h-3 w-3  ${checked ? classes.checked : classes.unchecked}`
                  )}
                />
              }
              name={option?.label || ""}
              onChange={onCheckboxChange}
              role="listItem"
            />
          )
        }
        label={
          <Typography
            className={labelClassName ?? typographyStyles.rhBaseBody2}
            style={{
              textTransform: "capitalize",
              marginTop: "1px",
              ...labelStyle
            }}
          >
            {shouldHideFilterCount
              ? he.decode(option?.localeLabel || optionLabel || "")
              : `${he.decode(option?.localeLabel || optionLabel || "")} (${
                  option?.count
                })`}
          </Typography>
        }
      />
    );
  }
);

interface RefinementOptionDataProps {
  refinement: SearchRefinementMenu;
  changeNavState: (navState: string) => void;
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
}

const RefinementOptionData: FC<RefinementOptionDataProps> = ({
  refinement,
  changeNavState,
  labelStyle,
  labelClassName
}) => {
  return (
    <FormGroup role="list">
      {(refinement?.refinements ?? []).map((option, i) => (
        <CheckboxType
          key={`option-${option?.label}-${i}`}
          id={`refinementOptionData_checkbox-${option?.label}`}
          option={option}
          handleChangeNavState={changeNavState}
          refinement={refinement}
          labelStyle={labelStyle}
          labelClassName={labelClassName}
        />
      ))}
    </FormGroup>
  );
};

export default memoize(RefinementOptionData);
